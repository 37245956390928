/**
 * Page About
 * Render About view
 */
import React from "react"

import Layout from "../components/layout"
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap'
import SEO from "../components/seo"
import Jumbotron from '../components/Jumbotron'
import AvatarInfoImg from '../images/contact-us-contact-us-page.png';

const AboutPage = () => (
  <Layout pageTitle="Tentang">
    <SEO 
      title="Tentang" 
      lang="id"
      img="https://firebasestorage.googleapis.com/v0/b/waingapu-open-mind.appspot.com/o/thumbnail%2Fcontact-us-contact-us-page.png?alt=media&token=50377a0a-78b7-4e81-8097-04904d35e621"
      description="Tentang Waingapu Open Mind"      
    />
    <Jumbotron title="Tentang Waingapu Open Mind" info="Mengenal lebih dalam tentang Waingapu Open Mind, situs media informasi yang di tuliskan oleh anak-anak waingapu yang memiliki pikiran kristis dan terbuka." />
	<Container>
 		<Row>
 			<Col md="12">
		 		<h2>Waingapu Open Mind ? </h2>
		 		<p className="text-left lead">
					Waingapu Open Mind adalah media informasi yang mewadahi argumen-argumen anak-anak waingapu terhadap isu-isu peristiwa yang telah, sedang, dan akan terjadi di dunia ini yang dituangkan kedalam suatu artikel. Dalam menuangkan argumen-argumen yang akan disampaikan yang nantinya akan diolah menjadi sebuah artikel, setiap penulis harus wajib memiliki minimal 1 buah sumber terpercaya yang jelas sehingga dapat meyakinkan para pembaca. 
		 		</p> 
		 		<p className="text-left lead">
		 			Waingapu Open Mind dalam menyampaikan informasi yang memiliki kredibilitas baik sangat menjunjung tinggi realita dari informasi, sehingga Waingapu Open Mind menjadi media informasi yang <span style={{fontWeight: 'bold'}}>SUMBER JELAS, INDEPENDEN, TRANSPARAN, APA ADANYA</span >
		 		</p> 
		 		<p className="text-left lead">
		 			Setiap artikel yang ada di Waingapu Open Mind merupakan hasil pemikiran yang ditulis oleh anak-anak Waingapu yang berpikir terbuka (open mind) mengenai isu-isu peristiwa yang telah, sedang dan akan terjadi di dunia ini. Masing-masing artikel juga memiliki setidaknya 1 buah label yang menjadi inti dari pokok permasalahan.
		 		</p> 

 			</Col>
 			<Col md="12">
	 			<br/>
		 		<h2>Ingin Menjadi Bagian Waingapu Open Mind ? </h2>
		 		<p className="text-left lead">
		 			Waingapu Open Mind memuat artikel-artikel menarik yang merupakan hasil curahan terdapap peristiwa yang telah, sedang dan akan terjadi di dunia ini oleh anak-anak waingapu yang berpikir kristis dan terbuka dan berani untuk di publikasin didepan umum.
		 		</p> 
		 		<div>
			 		<p className="text-left lead">
			 			Jikalau kalian ingin menuangkan tulisan kalian untuk dijadikan informasi yang bermanfaat silakan:
			 		</p> 
			 		<ol>
			 			<li className="lead">Kirim naskah kalian ke email redaksi ke <span style={{fontWeight: 'bold'}}>umburambu45@gmail.com</span> dengan subjek <span style={{fontWeight: 'bold'}}>openmindwaingapupoeple</span></li>
			 			<li className="lead">Atau kalian bisa menghubungi ke email <span style={{fontWeight: 'bold'}}>umburambu45@gmail.com</span> atau hubungi via WhatsApp <span style={{fontWeight: 'bold'}}>+62-822-17971133</span> untuk mendapatkan informasi lebih lanjut</li>
			 		</ol>
			 		<p className="text-left lead">
			 			KETENTUAN : 
			 		</p> 
			 		<ol>
			 			<li className="lead">Penulis bebas ingin menganonimkan dirinya didepan publik atau tidak</li>
			 			<li className="lead">Semua artikel yang masuk akan tetap menjadi hak milik penulis, tanggung jawab artikel akan diberikan kepada penulis.</li>
			 			<li className="lead">Penulis yang sudah masuk kedalam team Waingapu Open Mind wajib setidaknya minimal menuliskan 1 buah artikel setiap bulan.</li>
			 			<li className="lead">Jika dalam 3 hari karya tulis yang dikirimkan belum juga mendapat balasan/dipulikasi dari Kami, silakan hubunngi via WhatsApp nomor Redaksi <span style={{fontWeight: 'bold'}}>+62-822-17971133</span>.</li>
			 			<li className="lead">Sebelum artikel dimuatkan, Redaksi kami akan melakukan penyuntingan tulisan terlebih dahulu demi menghindari kesalahan penulisan kata.</li>
			 			<li className="lead">Penulis/Orang yang sudah masuk kedalam team Waingapu Open Mind akan diminta identitas diri (Nama, Bio, Sosial media) yang sifatnya opsional.</li>
			 			<li className="lead">Informasi lebih lanjut silakan hubungi ke email <span style={{fontWeight: 'bold'}}>umburambu45@gmail.com</span> atau via WhatsApp <span style={{fontWeight: 'bold'}}>+62-822-17971133</span></li>
			 		</ol>
		 		</div>
 			</Col>
 			<Col md="12" className="mb-4">
 				<Row>
 					<Col md="8">
						<Card>	
							<CardTitle className="text-center mt-2 p-auto text-white bg-dark"><h3>Info</h3></CardTitle>
							<CardBody>
			          <ul className="list-group list-group-flush">
			            <li className="list-group-item">
					          <p><i className="far fa-envelope"></i> umburambu45@gmail.com</p>
			            </li>
			            <li className="list-group-item">
			            	<p><i className="fas fa-map-marker-alt"></i> Waingapu, Sumba Timur, Nusa Tenggara Timur, Indonesia</p>
									</li>
								  <li className="list-group-item">
											<p><i className="fas fa-phone-square"></i> +62-822-17971133</p>
									</li> 
			          </ul>
							</CardBody>
						</Card>
 					</Col>
 					<Col md="4">
          			  <img src={AvatarInfoImg} className="img-fluid" alt="AvatarImg" /> 						
 					</Col>
 				</Row>
 			</Col>
 		</Row>
	<br/>
	<br/>
 		<Container>
	 		<Container>
	        	<h1 className="text-dark text-center"><q>Sedikit bacot banyak tau</q></h1>	
	 		</Container>
 		</Container>
    </Container>
	<br/>
	<br/>
	<br/>
	<br/>
	<br/>
  </Layout>
)
 
export default AboutPage

 